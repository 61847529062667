
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Container, Heading } from "@chakra-ui/react";
import { useTranslation } from "src/utils/useTranslation";
function NotFound() {
    const { t } = useTranslation();
    return (<Container maxW="container.md">
      <Heading>{t("Fant ikke siden")}</Heading>
    </Container>);
}
export default NotFound;

    async function __Next_Translate__getStaticProps__193e424ee56__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193e424ee56__ as getStaticProps }
  